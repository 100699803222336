import {withAccessToken} from '../utils/auth/authenticationUtils';
import {ajax} from 'rxjs/ajax';
import appConfig from '../utils/config/appConfig';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';

const toTransaction = (emspTransaction) => ({
      id: emspTransaction.id,
      status: emspTransaction.status,
      user: {
        chargeTagId: emspTransaction.user.charge_tag_id,
        accountName: emspTransaction.user.account_name,
      },
      charger: {
        id: emspTransaction.charger.charger_display_name,
        address: emspTransaction.charger.address,
        owner: emspTransaction.charger.owner,
      },
      session: {
        durationInSeconds: emspTransaction.session.duration_in_seconds,
        kwh: emspTransaction.session.kwh,
        startTime: emspTransaction.session.start_date_time,
        parkingDurationInSeconds: emspTransaction.session.parking_duration_in_seconds,
        chargeableDurationInSeconds: emspTransaction.session.chargeable_duration_in_seconds,
        parkingGraceDurationInSeconds: emspTransaction.session.parking_grace_duration_in_seconds,
      },
      tariff: {
        ratePerKwh: emspTransaction.tariff.rate_per_kwh,
        ratePerMinute: emspTransaction.tariff.rate_per_minute,
        unlockFee: emspTransaction.tariff.unlock_fee,
        ratePerParkingMinute: emspTransaction.tariff.rate_per_parking_minute,
      },
      payment: {
        kwhFeeTotal: emspTransaction.payment.kwh_fee_total,
        minuteFeeTotal: emspTransaction.payment.minute_fee_total,
        unlockFeeTotal: emspTransaction.payment.unlock_fee_total,
        parkingFeeTotal: emspTransaction.payment.parking_fee_plus_grace_cost,
        parkingGraceCost: emspTransaction.payment.parking_grace_cost,
        totalCost: emspTransaction.payment.total_cost,
        totalPaid: emspTransaction.payment.total_paid,
      },
      paymentBreakdown: {
        prepaidAmount: emspTransaction.payment.payment_breakdown?.prepaid_amount ?? 0,
        creditCardAmount: emspTransaction.payment.payment_breakdown?.credit_card_amount ?? 0,
        postpayAmount: emspTransaction.payment.payment_breakdown?.postpay_amount ?? 0,
      },
      cardDetails: emspTransaction.payment.card_details ?
        {
          brand: emspTransaction.payment.card_details.brand,
          lastFourDigits: emspTransaction.payment.card_details.last_four_digits,
        } : null
    }
  )
;


const toTransactions = (emspTransactions) => emspTransactions.map(toTransaction);

const transactionService = {
  getTransactions: () =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomainV4}/transactions`,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
    ).pipe(
      map((response) => {
        return toTransactions(response.response.data);
      }),
      catchError((error) => throwError(new Error('Unable to fetch transactions!')))
    ),
  getTransaction: (id) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomainV4}/transactions/${id}`,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
    ).pipe(
      map((response) => toTransaction(response.response)),
      catchError((error) => throwError(new Error(`Unable to fetch the transaction with id ${id}!`)))
    )
};

export default transactionService;
