import React, {useCallback, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import {ErrorBar} from '../../commons/snackbars/snackbars';
import Typography from '@material-ui/core/Typography';
import {Box, Divider} from '@material-ui/core';
import dateTimeFormatter from '../../utils/date/dateTimeFormatter';
import mainTheme from '../../themes/mainTheme';
import {useParams} from 'react-router';
import transactionService from '../../services/transactionService';
import {useLoadingContainerWithErrorPanel} from '../../hooks/useLoadingContainerWithErrorPanel';
import Dashboard from '../Dashboard/Dashboard';
import Alert from '@material-ui/lab/Alert';
import Spinner from 'react-spinkit';
import PaperWithHeading from '../../commons/containers/PaperWithHeading/PaperWithHeading';
import CreditCardEmoji from '../CreditCard/credit_card_emoji';
import TransactionCostDisplayTable from './TransactionCostDisplayTable';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 600,
  },
  row: {
    gap: theme.spacing(1),
  },
  cardDots: {
    fontSize: '0.4rem',
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  alert: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.connectorStatus.charging,
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(3),
  },
  activeIcon: {
    marginTop: 12,
    color: theme.palette.primary.contrastText,
    '& div': {
      width: theme.spacing(0.25),
      height: theme.spacing(0.25),
    },
    '& div:first-child': {
      display: 'none',
    },
  },
}));

const TransactionContent = ({transaction}) => {
  const classes = useStyles(mainTheme);
  
  const buildCostBreakdown = () => {
    const fee = [];
    if (transaction.tariff.ratePerKwh || transaction.session.kwh) {
      fee.push({
        tariff: '$/kWh',
        rate: `$${transaction.tariff.ratePerKwh.toFixed(2)}/kwh`,
        usage: transaction.session.kwh,
        cost: transaction.payment.kwhFeeTotal,
      });
    }
    
    if (transaction.tariff.ratePerMinute) {
      fee.push({
        tariff: '$/min',
        rate: `$${transaction.tariff.ratePerMinute.toFixed(2)}/min`,
        usage: dateTimeFormatter.printReadableTimeFromSeconds(transaction.session.chargeableDurationInSeconds),
        cost: transaction.payment.minuteFeeTotal,
      });
    }
    
    if (transaction.tariff.unlockFee) {
      fee.push({
        tariff: 'Unlock Fee',
        rate: `$${transaction.tariff.unlockFee.toFixed(2)} unlock`,
        usage: '',
        cost: transaction.payment.unlockFeeTotal,
      });
    }
    if (transaction.tariff.ratePerParkingMinute) {
      fee.push({
        tariff: 'Idle Fee',
        rate: `$${transaction.tariff.ratePerParkingMinute.toFixed(2)}/min`,
        usage: dateTimeFormatter.printReadableTimeFromSeconds(transaction.session.parkingDurationInSeconds),
        cost: transaction.payment.parkingFeeTotal,
      });
    }
    
    return fee;
  };
  
  const creditBreakdownData = () => {
    if (!transaction.tariff.ratePerParkingMinute) return [];
    return [
      {
        tariff: 'Idle Fee',
        rate: `-$${transaction.tariff.ratePerParkingMinute.toFixed(2)}/min`,
        usage: dateTimeFormatter.printReadableTimeFromSeconds(transaction.session.parkingGraceDurationInSeconds),
        cost: -transaction.payment.parkingGraceCost,
      }
    ];
  };
  
  const buildTotalCost = () => {
    const total = transaction.status === 'ACTIVE' ? transaction.payment.totalCost : transaction.payment.totalPaid;
    return total === 0 ? 'FREE' : `$${total.toFixed(2)}`;
  };
  
  const shouldShowPaymentBreakdown = transaction.status === 'COMPLETED' && (transaction.paymentBreakdown.prepaidAmount !== 0 || transaction.paymentBreakdown.creditCardAmount !== 0 || transaction.paymentBreakdown.postpayAmount !== 0);
  const costBreakdownData = buildCostBreakdown();
  const creditBreakdown = creditBreakdownData();
  
  return (
    <PaperWithHeading heading={transaction.status === 'ACTIVE' ? 'Active Transaction' : 'Transaction Summary'}>
      <>
        {transaction.status === 'ACTIVE' && (
          <>
            <Alert className={classes.alert} severity="info" icon={<Spinner className={classes.activeIcon} name="ball-clip-rotate-multiple" fadeIn="none"/>}>
              Active transaction!
              <br/>
              Usage and costs are not final.
              <br/>
              Please refresh the page to see updated data.
            </Alert>
          </>
        )}
        
        <Typography className={classes.bold} align="left">{transaction.charger.address}</Typography>
        <Typography className={classes.bold} align="left">{transaction.charger.owner}</Typography>
        <Typography align="left">Station Id: {transaction.charger.id}</Typography>
        <Divider className={classes.divider}/>
        
        <Typography className={classes.bold} align="left">Started charging at:</Typography>
        <Typography align="left">{dateTimeFormatter.printReadableDateTime(transaction.session.startTime)}</Typography>
        <Divider className={classes.divider}/>
        {costBreakdownData.length > 0 && (
          <>
            <TransactionCostDisplayTable columns={[
              {title: 'Tariff', field: 'tariff', align: 'left'},
              {title: 'Rate', field: 'rate', align: 'left'},
              {title: 'Usage', field: 'usage'},
              {title: 'Cost', field: 'cost', type: 'currency'}
            ]} data={costBreakdownData}/>
            <Divider className={classes.divider}/>
            {creditBreakdown.length > 0 && (
              <>
                <TransactionCostDisplayTable columns={[
                  {title: 'Credit', field: 'tariff', align: 'left'},
                  {title: 'Rate', field: 'rate', align: 'left'},
                  {title: 'Usage', field: 'usage'},
                  {title: 'Cost', field: 'cost', type: 'currency'},
                ]
                } data={creditBreakdownData()}/>
                <Divider className={classes.divider}/>
              </>
            )}
          </>
        )}
        
        <Typography className={classes.bold} align="left">
          Total Cost: {buildTotalCost()}
        </Typography>
        {transaction.status === 'PAYMENT_FAILED' && (
          <Typography color="error" align="left">
            Note: The payment for this transaction has failed
          </Typography>
        )}
        
        {shouldShowPaymentBreakdown && (
          <>
            <Divider className={classes.divider}/>
            {transaction.paymentBreakdown.prepaidAmount > 0 && (
              <Typography className={classes.bold} align="left">
                Prepaid Amount Charged: ${transaction.paymentBreakdown.prepaidAmount.toFixed(2)}
              </Typography>
            )}
            {transaction.paymentBreakdown.creditCardAmount > 0 && (
              <Typography className={classes.bold} align="left">
                Amount Paid By Credit Card: ${transaction.paymentBreakdown.creditCardAmount.toFixed(2)}
              </Typography>
            )}
            {transaction.paymentBreakdown.postpayAmount > 0 && (
              <>
                <Box display="flex">
                  <Typography className={classes.bold} align="left">
                    Account Name:&nbsp;
                  </Typography>
                  <Typography align="left">
                    {transaction.user.accountName}
                  </Typography>
                </Box>
                <Typography className={classes.bold} align="left">
                  Postpay Amount Charged: ${transaction.paymentBreakdown.postpayAmount.toFixed(2)}
                </Typography>
              </>
            )}
          </>
        )}
        
        <Divider className={classes.divider}/>
        <Typography className={classes.bold} align="left">
          Transaction Id: {transaction.id}
        </Typography>
        
        {transaction.cardDetails && transaction.status === 'COMPLETED' && (
          <Box display="flex" alignItems="stretch" className={classes.row}>
            <Box display="flex" flexDirection="column" alignItems="start">
              <Typography className={classes.bold}>Payment type: </Typography>
              <Typography className={classes.bold}>Card number: </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="end" justifyContent="space-between">
              <CreditCardEmoji size={20} brand={transaction.cardDetails.brand}/>
              <Box display="flex" alignItems="center" flexWrap="wrap" className={classes.row}>
                <Typography className={classes.cardDots}>● ● ● ●</Typography>
                <Typography className={classes.cardDots}>● ● ● ●</Typography>
                <Typography className={classes.cardDots}>● ● ● ●</Typography>
                <Typography>{transaction.cardDetails.lastFourDigits}</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </>
    </PaperWithHeading>
  );
};

// Fetches a transaction
const fetchTransactionDetails = (id) => {
  return transactionService.getTransaction(id)
};

const Transaction = () => {
  const {id} = useParams();
  
  const [transaction, setTransaction] = useState(null);
  
  const observable = useMemo(() => fetchTransactionDetails(id), [id]);
  const onSuccessCallback = useCallback((response) => setTransaction(response), []);
  const onErrorCallback = useCallback((error) => setTransaction(null), []);
  
  const loadingContainerWithErrorPanelState = useLoadingContainerWithErrorPanel(observable, onSuccessCallback, onErrorCallback);
  
  return (
    <Dashboard title={"Transaction"}>
      <>
        <LoadingContainerWithErrorPanel {...loadingContainerWithErrorPanelState}>
          <TransactionContent transaction={transaction}/>
        </LoadingContainerWithErrorPanel>
        <ErrorBar open={loadingContainerWithErrorPanelState.hasError}>{loadingContainerWithErrorPanelState.errorMessage}</ErrorBar>
      </>
    </Dashboard>
  );
};

Transaction.propTypes = {};

export default Transaction;
