import React from 'react';
import MaterialTable from 'material-table';
import {tableIcons} from '../../commons/table/tableIcons';
import mainTheme from '../../themes/mainTheme';

const TransactionCostDisplayTable = ({columns, data}) => {
  return (
    <MaterialTable
      icons={tableIcons}
      components={{
        Container: (props) => props.children,
        Toolbar: () => <></>,
      }}
      style={{
        padding: '0 16px',
      }}
      columns={columns}
      data={data}
      options={{
        headerStyle: {
          color: mainTheme.palette.primary.dark,
          fontWeight: 600,
          padding: `0`,
          borderBottom: '0',
        },
        cellStyle: {
          width: '25%',
          padding: `0`,
          borderBottom: '0',
        },
        showTitle: false,
        search: false,
        paging: false,
        sorting: false,
        draggable: false,
      }}
    />
  );
};

export default TransactionCostDisplayTable;